body {
  background-color: #090d0d;
  color: white;
}

.bg-resource {
    background: #010103;
}

.bg-footer {
  background-color: #ffffff04;
}

.bg-grad {
    background: linear-gradient(91deg,#07c57f 1.31%,#08b396 100%);
}

.partner {
  opacity: 30%;
}

.partner:hover {
  opacity: 100%;
  cursor: pointer;
}

.bg-porto {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg,#090d0d 30%,#00000000 100%);
}

.btn-main {
  background: linear-gradient(91deg,#07c57f 1.31%,#08b396 100%);
  color: white;
  border: #07c57f solid 1px;
  transition: .5s;
}

.btn-outline-main {
  background: transparent;
  color: #07c57f;
  border: #07c57f solid 1px;
  transition: .5s;
}

.btn-main:hover {
  background: transparent;
  color: #07c57f;
  border: #07c57f solid 1px;
}

.btn-outline-main:hover {
  background: linear-gradient(91deg,#07c57f 1.31%,#08b396 107.34%);
  color: white;
  border: #07c57f solid 1px;
}

.code {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -200px;
  display: block;
}

.hero-2 {
  background-color: transparent;
  background-size: cover;
  background-position: center;
}

.hero-2::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1a2727;
}

.hero-3::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1a2727;
}

.hero-two::after {
  background: #121a1a !important;
}

.hero-1::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;  
  background: #121a1a;
}

.hero-3 {
  background-image: transparent;
  background-size: cover;
  background-position: center;
}

.tshadow {
  text-shadow: 0px 0px 12px rgba(0, 0, 0, .35);
}

.hero {
  height: 50vh;
  cursor: pointer;
  transition: 0.5s ease-in;
  overflow: hidden;
  padding: 20px;
  position: relative;
}

.hero-1 {
  flex: 1;
}

.hero-2 {
  flex: 1;
}

.hero-3 {
  flex: 1;
}

.hero-active {
  flex: 4;
  position: relative;
}

.hero-active::after {
  content: '';
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(91deg,#121a1a 1.31%,#1a2727 107.34%);
}

.hero-active .content {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.content {
  position: relative;
  z-index: 999;
  opacity: 0;
  color: white;
  transform: translateX(-1000px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  transition: 1s;
}


/* Banner */
.banner {
  width: 100%;
  padding: 45px 0px;
  background-color: transparent;
  position: relative;
}

.banner::before {
  content: '';
  display: block;
  z-index: -1;
  position: absolute;
  top: 0;bottom: 0;left: 0;right: 0;
  background-color: rgba(255, 255, 255, 0.1);
}

.card {
  background-color: transparent !important;
}

.card::before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 8px !important;
  top: 0;bottom: 0;left: 0;right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
}

@media (max-width: 568px) {
  .container-x {
    padding: 0 10px;
  }

  .hero {
    height: 50vh;
    padding: 8px;
  }
}

.font-grad {
  background: linear-gradient(91deg,#48ffbd 1.31%,#32e0c4 107.34%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ornament-right {
  position: absolute;
  right: 0;top: 0;
  opacity: .75;
  z-index: -1;
}

.ornament-left {
  position: absolute;
  left: 0;top: 0;
  opacity: .6;
  z-index: -1;
}

@media (max-width: 576px) {
  .ornament-left {
    opacity: .05;
  }

  .ornament-right {
    opacity: .05;
  }
}

.card-neon {
  border: 1px solid rgb(59, 59, 59);
  box-shadow: 0 4px 24px rgba(255, 255, 255, .15);
}

/* -------------------------------- 

💡 How to create gradient borders in CSS
🔗 https://codyhouse.co/nuggets/css-gradient-borders

-------------------------------- */

/* method 1 -> use border-image */
/* .border-neon {
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to right, darkblue, darkorchid) 1;
  border-radius: 12px;
}

.border-neon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: darkblue;
  padding: 0.5rem 1rem;
  cursor: pointer;
} */

@media (max-width: 567px) {
  .text-stats {
    display: none;
  }

  .m-resp-custom {
    margin-left: 20px;
  }
}