body {
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-link {
  font-size: 14px !important;
}

.container-x {
  padding: 0 160px;
}

header {
  height: 100vh;
}

.text-gray {
  color: #333;
}

.text-custom {
  text-align: center;
}

@media (max-width: 768px) {
  .text-custom {
    text-align: left;
  }

  .brand-img-1 {
    bottom: unset !important;
    right: -140px;
    top: 0px;
  }
  
  .brand-img-2 {
    bottom: unset !important;
    right: -140px;
    top: 0px;
  }
  
  .brand-img-3 {
    bottom: unset !important;
    top: 0px;
    right: -80px;
  }
  
  .brand-img {
    position: absolute !important;
    width: 300px !important;
  }
}

.brand-img {
  position: absolute !important;
  width: 400px;
}

.brand-img-1 {
  bottom: -100px;
  right: -140px;
}

.brand-img-2 {
  bottom: -70px;
  right: -140px;
}

.brand-img-3 {
  bottom: -150px;
  right: -80px;
}